import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/base.service';
import { uiwConstants } from '@shared/services/uiw-Constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToasterService } from '@core/toaster.service';
import { Router } from '@angular/router';
import { uiwProjectList } from '../models/uiw-projectList.model';

@Injectable({
    providedIn: 'root'
})
export class UiwDataServiceService extends BaseService {

    constructor(private http: HttpClient,
                public router: Router,
                public toaster: ToasterService) {
        super(router, toaster);
    }

    getProjectList(pageNumber, searchText?): Observable<uiwProjectList> {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getProjectList}`;
        url = url.replace('{pageNumber}', pageNumber);
        url = url.replace('{perPage}', uiwConstants.perPageProject);

        if (searchText == undefined) {
            url = url.replace('{searchText}', "");
        } else {
            url = url.replace('{searchText}', searchText);
        }
        return this.http.get<uiwProjectList>(url);
    }

    getFeatures(buildCardId, platform , device,role, showArtboardName? ,hideHotSpot?,studioPlatformIds? ,trackerId?) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getFeatures}`;
        uri = uri.replace("{buildCardId}", buildCardId).replace("{platform}", platform).replace("{device}", device);
        if(role){
            uri = `${uri}&role_id=${role}`;
        }
        if(showArtboardName) uri = `${uri}&show_artboard_name=${showArtboardName}`;
        if(hideHotSpot) uri =`${uri}&hide_hotspots=${hideHotSpot}`;
        if(studioPlatformIds) uri = `${uri}&studio_platform_ids=${studioPlatformIds}`;
        if(trackerId) uri = `${uri}&tracker_project_id=${trackerId}`;
        return this.http.get(uri);
    }
    //MERGINGTRACKER-13
    getComments(projectId,platform,role ,journeyId?) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getComments}`;
        uri = uri.replace("{projectId}", projectId);
        if(platform && !journeyId){
          uri = `${uri}?platform_name=${platform}`;
        }        
        if(role && !journeyId){
            uri = `${uri}&role_id=${role}`;
        }

        if(journeyId){
            uri = `${uri}?journey_id=${journeyId}`;
        }
        return this.http.get(uri);
    }

    getActivities(payload,role,timeZone,journeyId?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getActivities}`;
        url = url.replace('{projectId}', payload.projectId);
        url = url.replace('{pageNumber}', payload.pageNumber);
        if(payload.platform_name){
        url += url.includes('?') ? `&platform_name=${payload.platform_name}` : `?platform_name=${payload.platform_name}`;
        }
        if(payload.story_id){
         url += url.includes('?') ? `&story_id=${payload.story_id}` : `?story_id=${payload.story_id}`;
        }

        if(role){
            url = `${url}&role_id=${role}`;
        }
        if(timeZone){
            url = `${url}&time_zone=${timeZone}`;
        }
        if(journeyId){
            url = `${url}&journey_id=${journeyId}`;
        }
        return this.http.get(url); 
    }

    postComment(projectId, featureId, comment, storyId) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.postComment}`;
        uri = uri.replace("{projectId}", projectId).replace("{storyId}", featureId);
        const payload = {
            'tracker_story_id': storyId,
            'note': {
                'note': comment
            }
        };
        return this.http.post(uri, payload);
    }

    getProjectDetails(projectId,isMinimal?) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.projectDetail}`;
        uri = uri.replace('{projectId}', projectId);
        if(isMinimal) uri += '?minimal=true';
        return this.http.get(uri);
    }

    setStoryEstimates(feature, estimatedTime, projectId,isIpm) {
        //storyId change--1 feature.studio_feature_id-----> feature.story.id
         let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.setStoryEstimates}`;
        uri = uri.replace('{storyId}', feature.story.id).replace('{projectId}', projectId);
        let payload = {
            story: {
                estimate: estimatedTime
            }
        };
        if(!isIpm){
            payload['studio_feature_id']=feature.studio_feature_id;
        }
        return this.http.put(uri, payload);
    }

    startTimer(feature, projectId,isIpm) {
        //storyId change--2 feature.studio_feature_id-----> feature.story.id
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.startTimer}`;
        uri = uri.replace('{storyId}', feature.story?.id).replace('{projectId}', projectId);
        let payload = {};
        if(!isIpm){
            payload['studio_feature_id']=feature.studio_feature_id;
        }
        uri += uri.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.put(uri, payload);
    }

    stopTimer(feature, projectId,isIpm) {
        //storyId change--3 on stop timer feature.studio_feature_id-----> feature.story.id
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.stopTimer}`;
        uri = uri.replace('{storyId}', feature.story?.id).replace('{projectId}', projectId);
        let payload = {};
        if(!isIpm){
            payload['studio_feature_id']=feature.studio_feature_id;
        }
        uri += uri.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.put(uri, payload);
    }

    setStoryStatus(feature, status, projectId,isIpm,isWireFrame, confirmedStartStory = false) {
        //storyId change--4 on change story status
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.storyStatus}`;
        uri = uri.replace('{storyId}', feature.story?.id).replace('{projectId}', projectId);
        let payload={};
        if(status == 'delivered'){
            if(!isIpm && !isWireFrame){
                payload['version']= feature?.build_card_hero_images[0].version;
                payload['studio_feature_id']=feature.studio_feature_id;
                payload['story'] = {design_status: status};
            }
            else if(isIpm || isWireFrame){
                payload['story'] = {state: status};
            }
        }
        else{
              payload = {
                 story: {
                    state: status,
                    confirmed: confirmedStartStory
                },
            }
             if(!isIpm){
                payload['version']= feature?.build_card_hero_images?.[0]?.version;
                payload['studio_feature_id']=feature.studio_feature_id;
            }
        }
        uri += uri.includes('?') ? '&notoast=1' : '?notoast=1';
        //this is required for socket
        if(status == 'started') payload['story'].paused = false;
        return this.http.put(uri, payload);
    }

    uploadDesign(params, isImage?) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.uploadDesign}`;
        uri = uri.replace('{storyId}', params.featureData.studio_feature_id).replace('{projectId}', params.projectId);
        const formData = new FormData();
        const primaryHeroImage = params.featureData?.build_card_hero_images.find(heroImg => heroImg.is_primary);
        if (primaryHeroImage) {
            formData.append('document[version]', primaryHeroImage.version);
        } else {
            formData.append('document[version]', params.featureData?.build_card_hero_images[0].version);
        }

        if(params.hero_image_feature_id){
            formData.append('hero_image_feature_id', params.hero_image_feature_id);
        }

        formData.append('document[uiw_uploaded]', 'true');
        formData.append('story_type', params.storyType);
        formData.append('tracker_story_id', params.featureData.story.id);
        if (isImage) {
            formData.append('file', params.file);
            formData.append('document[name]', params.file.name);
        } else {
            formData.append('file', params.file.imageFile);
            formData.append('document[name]', params.file.imageFile.name);
        }
        if (!params.isSubStory) {
            formData.append('document[is_primary]', 'true');
        }
        return this.http.post(uri, formData);
    }
    //MERGINGTRACKER-14
    getStrories(payload,role?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getStrories}`;
        url = url.replace('{projectId}', payload.projectId);
        url = url.replace('{allStory}', payload.allStory);
        if(payload.platform_name){
            url = `${url}&platform_name=${payload.platform_name}`;
        }
        if(role){
            url = `${url}&role_id=${role}`;
        }
        return this.http.get(url);
    }


    getNotification({ pageNumber, projectId }): Observable<any> {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getProjectNotifications}`;
        url = url.replace('{pageNumber}', pageNumber).replace('{projectId}', projectId);
        return this.http.get(url);
    }
    getAllNotications({ pageNumber }): Observable<any> {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getAllNotifications}`;
        url = url.replace('{pageNumber}', pageNumber);
        return this.http.get(url);
    }
    //MERGINGTRACKER-15
    getStoryInfo(payload) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getStoryInfo}`;
        url = url.replace('{storyId}', payload.storyId).replace('{projectId}', payload.projectId);
        if(!payload.is_ipm){
            url = url + '?studio_feature_id=' + payload.featureId;
        }
        else{
            url = `${url}?is_ipm=true`;
        }
        return this.http.get(url);
    }

    getConfigData(): Observable<any[]> {
        const url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.uiwConfig}`;
        return this.http.get<any[]>(`${url}`);
    }

    editHotspot(feature, hotspot, scaleFactor, form, currentProject) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.editClickableItem}`;
        if (form.destination) url = url.replace('{hotspotId}', hotspot.hotspotId).replace('{clickableItemId}', hotspot.id);
        else  url = url.replace('{hotspotId}', hotspot.hotspotId).replace('{clickableItemId}', '00');
        const payload = {
            "clickable_item": {
                "target_feature_uniq_code": form.destination ? form.destination?.uniq_code : '',
                "target_bchi_id":form.destination ? form.destination?.target_bchi_id : '',
                "transition": form.transition ? form.transition.key : '',
                "gesture": form.action ? form.action.event_type : '',
                "image_attributes": {
                    "height": hotspot.shape.height * scaleFactor,
                    "width": hotspot.shape.width * scaleFactor,
                    "originX": hotspot.shape.originX * scaleFactor,
                    "originY": hotspot.shape.originY * scaleFactor,
                    "percent_width": hotspot.shape.percent_width,
                    "percent_height": hotspot.shape.percent_height,
                    "percent_originX": hotspot.shape.percent_originX,
                    "percent_originY": hotspot.shape.percent_originY
                }
            },
            "build_card_id": currentProject.build_card_id,
            "device": currentProject.device,
            "platform": feature.platform,
            "version": feature.version,
            "category_id": form.category ? form.category.id : hotspot.hotspotCategoryId,
            "sketch_element": form.screenName ? form.title : '',
            "screen_name": form.screenName ? form.screenName.id === uiwConstants.guidSketchExtractedHotspots ? '' : form.screenName.value : '',
            "ui_element": form.uiElement ? form.uiElement.id === uiwConstants.guidSketchExtractedHotspots ? '' : form.uiElement.id : '',
            "target_artboard_name": form.destination ? form.destination?.artboard_name : '',
            "sketch_generated": hotspot.sketchGenerated
        };
        return this.http.put(url, payload);
    }

    createHotspot(featureData, hotspots, currentProject, platform, buildCardHeroImageId) {
        const url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.createHotspot}`;
        let device = currentProject.device ? currentProject.device : featureData.storyDevice;
        const payload: any = {
            "build_card_id": currentProject.build_card_id,
            "build_card_hero_image_id": buildCardHeroImageId,
            "device": device,
            "platform": platform,
            "build_card_feature_id": featureData.id,
            "version": featureData.version,
            "previous_build_card_hotspot_id": null,
            "previous_clickable_item_id": null,
            "build_card_hotspots": hotspots
        };
        // if (isNewHotspot) {
        //     payload.build_card_hotspot.designer_created_hotspot = true;
        // }
        return this.http.post(url, payload);
    }

    getPhases(projectId) {
        let url = `${this.builder360Url}${uiwConstants.uiwApiEndPoints.builder360}`;
        url = url.replace('{projectId}', projectId);
        return this.http.get(url);
    }

    getProjectDetails360(studio_build_card_id) {
        let url = `${this.builder360Url}${uiwConstants.uiwApiEndPoints.projectDetails360}`;
        url = url.replace('{studio_build_card_id}', studio_build_card_id);
        return this.http.get(url);
    }

    getIndividualFeature(storyId, buildCardId, platform, device,role , studioJourneyId?, wireframeFlag?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getSingleFeature}`;
        url = url.replace('{storyId}', storyId).replace('{buildCardId}', buildCardId).replace('{platform}', platform).replace('{device}', device);
        if(role){
            url = `${url}&role_id=${role}`;
        }
        if(!wireframeFlag && studioJourneyId){
            url = `${url}&studio_journey_id=${studioJourneyId}`;
        }
        return this.http.get(url);
    }

    getCommentsTracker(projectId) {
        let url = `${this.trackerStagingUrl}${uiwConstants.uiwApiEndPoints.getCommentsNumber}`;
        url = url.replace('{projectId}', projectId);
        return this.http.get(url);
    }

    createCommentsTracker(projectId, feature, user, text, coordinates?, comment?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.postComment}`;
        url = url.replace('{projectId}', projectId);
        const body: any = {
            "user_name": user.username,
            "user_type": "designer",
            "user_email": user.email,
            "set_as_designer_task": false,
            "note": text
        };

        if (feature) {
            const mainHeroImage = feature?.build_card_hero_images.find(img => img.is_primary);
            if (mainHeroImage) {
                body.version = mainHeroImage.version;
                body.hero_image_feature_id = mainHeroImage.build_card_hero_image_id;
            } else {
                body.version = feature?.build_card_hero_images.length > 0 && feature?.build_card_hero_images[0].version;
            }
            url = url.replace('{storyId}', feature.studio_feature_id || feature.builder_feature_id);
        } else {
            body.builder_feature_id = comment.feature.studio_feature_id || comment.feature.builder_feature_id;
            const mainHeroImage = comment.feature?.build_card_hero_images.find(img => img.is_primary);
            if (mainHeroImage) {
                body.version = mainHeroImage.version;
                body.hero_image_feature_id = mainHeroImage.build_card_hero_image_id;
            } else {
                body.version = comment.feature?.build_card_hero_images.length > 0 && comment.feature?.build_card_hero_images[0].version;
            }
            url = url.replace('{storyId}', comment.feature.studio_feature_id || comment.feature.builder_id);
        }

        if (coordinates) {
            body.position = { originX: coordinates.originX, originY: coordinates.originY };
        }
        if (comment) {
            body.position = { originX: comment.position.originX, originY: comment.position.originY };
            body.parent_id = comment.id;
        }
        if(feature) 
        {
            body.tracker_story_id = feature.story.id;
        }else{
            body.tracker_story_id = comment.feature.story.id;
        }
        return this.http.post(url, body);
    }

    createCommentsForVariants(projectId, variant, feature, user, text, coordinates?, comment?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.postComment}`;
        url = url.replace('{projectId}', projectId);
        url = url.replace('{storyId}', feature.studio_feature_id || feature.builder_feature_id);
        const body: any = {
            "user_name": user.username,
            "user_type": "designer",
            "user_email": user.email,
            "set_as_designer_task": false,
            "note": text
        };

        body.version = variant.version;
        body.hero_image_feature_id = variant.build_card_hero_image_id;

        if (coordinates) {
            body.position = { originX: coordinates.originX, originY: coordinates.originY };
        }
        if (comment) {
            body.position = { originX: comment.position.originX, originY: comment.position.originY };
            body.parent_id = comment.id;
            body.tracker_story_id = comment.feature.story.id;
        }

        if (feature) {
            body.tracker_story_id = feature.story.id;
        }
        return this.http.post(url, body);

    }

    getCommentData(projectId, comment) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.singleComment}`;
        url = url.replace('{projectId}', projectId).replace('{commentId}', comment.id).replace('{storyId}', comment.studio_feature_id);
        return this.http.get(url);
    }

    updateFeatureData(params, mainFeature, createVariant?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.updateFeature}`;
        url = url.replace('{featureId}', params.featureData.build_card_feature_id || params.featureData.id);
        const formData = new FormData();
        let mainHeroImage = null;
        if (params.featureData.build_card_hero_images && params.featureData.build_card_hero_images.length > 0) {
            mainHeroImage = params.featureData.build_card_hero_images.find(img => img.is_primary);
            if (mainHeroImage) {
                formData.append('build_card_hero_image_id', mainHeroImage.build_card_hero_image_id);
                formData.append('version', mainHeroImage.version);
            }
        } else {
            mainHeroImage = mainFeature.build_card_hero_images.find(img => img.is_primary);
            if (mainHeroImage) {
                formData.append('build_card_hero_image_id', mainHeroImage.build_card_hero_image_id);
                formData.append('version', mainHeroImage.version);
            }
        }
        formData.append('platform', params.platform);
        formData.append('device', params.device);
        formData.append('build_card_id', params.project.build_card_id);
        formData.append('file', params.file);
        formData.append('story_id', params.featureData.story.id);
        formData.append('story_type', params.storyType);
        formData.append('story_title', params.featureData.story.title);
        if(createVariant) {
            url = `${url}?create_variant=${createVariant}`;
        }
        url += url.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.put(url, formData);
    }

    deleteDesign(projectId, storyId, documentId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteDesign}`;
        url = url.replace('{storyId}', storyId).replace('{projectId}', projectId).replace('{documentId}', documentId);
        return this.http.delete(url);
    }

    updatePrimaryStory(projectId, storyId, documentId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.updateFeatureStory}`;
        url = url.replace('{storyId}', storyId).replace('{projectId}', projectId).replace('{documentId}', documentId);
        const formData = new FormData();
        formData.append('document[is_primary]', 'true');
        return this.http.put(url, formData);
    }

    deleteHotspot(id) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteHotspot}`;
        url = url.replace('{hotspotId}', id);
        return this.http.delete(url);
    }

    deleteClickableItem(hotspotId, clickableItemId, buildCardId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteClickableItem}`;
        url = url.replace('{build_card_hotspot_id}', hotspotId).replace('{clickable_item_id}', clickableItemId).replace('{studio_build_card_id}', buildCardId);
        return this.http.delete(url);
    }

    restartStoryApiCall(buildCardId, feature, device, platform) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.restartStory}`;
        url = url.replace('{featureId}', feature.build_card_feature_id || feature.id);
        const mainHeroImage = feature.build_card_hero_images?.find(img => img.is_primary);
        const formData = new FormData();
        formData.append('device', device);
        formData.append('build_card_id', buildCardId);
        formData.append('platform', platform);
        formData.append('story_id', feature.story?.id);
        formData.append('tracker_story_id', feature.story?.id);
        if (mainHeroImage) {
            formData.append('build_card_hero_image_id', mainHeroImage.build_card_hero_image_id);
            formData.append('version', mainHeroImage.version);
        } else {
            formData.append('build_card_hero_image_id', feature.build_card_hero_images?.[0].build_card_hero_image_id);
            formData.append('version', feature.build_card_hero_images?.[0].version);
        }
        return this.http.put(url, formData);
    }

    //change 8 sketch polling api
    getStoryStatusPolling(projectId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.pollingApi}`;
        url = url.replace('{projectId}', projectId);
        return this.http.get(url);
    }

    setAsPrimaryAttachment(feature) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.setPrimary}`;
        url = url.replace('{featureId}', feature.id);
        const formData = new FormData();
        formData.append('build_card_hero_image_id', feature.build_card_hero_image_id);
        return this.http.put(url, formData);
    }

    changePrimaryImage(feature, project, variant) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.setPrimary}`;
        url = url.replace('{featureId}', feature?.id);
        const formData = new FormData();
        formData.append('version', variant?.version);
        formData.append('build_card_id', project?.build_card_id);
        formData.append('build_card_hero_image_id', variant?.build_card_hero_image_id);
        return this.http.put(url, formData);
    }

    createVariant(feature, project, platform, file) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.createVariant}`;
        const formData = new FormData();
        const primaryHeroImage = feature.build_card_hero_images.find(heroImg => heroImg.is_primary);
        if (primaryHeroImage) {
            formData.append('version', primaryHeroImage.version);
        } else {
            formData.append('version', feature.build_card_hero_images[0].version);
        }
        formData.append('build_card_id', project.build_card_id);
        formData.append('build_card_feature_id', feature.id);
        formData.append('platform', platform);
        formData.append('image_attributes', "{}");
        formData.append('file', file);
        return this.http.post(url, formData);
    }
    afterVariantCreation(payload) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.updateFeatureStory}`;
        url = url.replace('{storyId}', payload.storyId).replace('{projectId}', payload.projectId).replace('{documentId}', payload.documentId);
        const formData = new FormData();
        formData.append('tracker_story_id', payload.tracker_story_id);
        formData.append('note[hero_image_feature_id]', payload.hero_image_feature_id);
        return this.http.put(url, formData);
    }
    deleteVariant(payload) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteVariant}`;
        url = url.replace('{id}', payload.id);
        const formData = new FormData();
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
              }),
            body: {
              build_card_id: payload.buildCardId,
              version: payload.version,
              platform: payload.platform,
              device: payload.device
            }
          }
        return this.http.delete(url, options);
    }
    deleteDesignAfterVariant(payload) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteDesignVariant}`;
        url = url.replace('{storyId}', payload.storyId).replace('{projectId}', payload.projectId).replace('{documentId}', payload.documentId);
        const formData = new FormData();
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
              }),
            body: {
                tracker_story_id :payload.tracker_story_id
            }
          }
        return this.http.delete(url,options);
    }

    readBaseSketch(payload){
        let url = `${this.baseSketch}${uiwConstants.uiwApiEndPoints.readSketchUrl}`;
        url = url.replace('{studio_Buildcard}', payload.studio_Buildcard);
        url += url.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.get(url);
    }

    readBaseSketchFeature(payload){
        let url = `${this.baseSketch}${uiwConstants.uiwApiEndPoints.readSketchUrlFeature}`;
        url = url.replace('{studio_feature_Id}', payload.studio_feature_Id);
        return this.http.get(url);
    }

    setAsLaunchScreen(payload,role){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.setAsLaunchScreen}`;
        url = url.replace('{build_card_id}', payload.build_card_id);
        const formData = new FormData();
        formData.append('build_card_feature_id', payload.build_card_feature_id);
        formData.append('device' , payload.device)
        formData.append('platform', payload.platform);
        formData.append('version', payload.version);
        if(role){
            url = `${url}?role_id=${role}`;
        }
        return this.http.put(url, formData);
    }
    
    reOrderApi(project_id,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.reOrder}`;
        url = url.replace('{project_id}',project_id);
        return this.http.put(url, payload);
    }

    brandingApi(studio_build_card_id, filters?){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.branding}`;
        url = url.replace('{studio_build_card_id}',studio_build_card_id);
        return this.http.post(url, {filters});
    }

    multiUpload(projectId, storyId, payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.multiUpload}`;
        url = url.replace('{project_id}',projectId);
        url = url.replace('{story_id}',storyId);
        const formData = new FormData();
        formData.append('story_type', payload.story_type);
        if (payload.story_type === 'sub_task') formData.append('cmt_id', payload?.featureData?.cmt_id);
        formData.append('tracker_story_id', payload.tracker_story_id);
        formData.append('artboard_name', payload.artboard_name);
        if(payload.image){
            formData.append('image[uiw_uploaded]', payload.image.uiw_uploaded);
            formData.append('image[file]', payload.image.file);
            formData.append('image[name]', payload.image.name); 
        }
        formData.append('sketch[uiw_uploaded]', payload.sketch?.uiw_uploaded);
        if (payload.sketch?.file) formData.append('sketch[file]', payload.sketch.file);
        formData.append('sketch[name]', payload.sketch?.name);
        formData.append('create_variant', payload.isCreateVariant)
        formData.append('platform', payload.platform);
        formData.append('device', payload.device);
        formData.append('build_card_id', payload.project.build_card_id);
        formData.append('build_card_feature_id', payload.featureData?.build_card_feature_id || payload.featureData?.id)
        let mainHeroImage = null;
        if (payload.hero_image_feature_id)
            formData.append('build_card_hero_image_id', payload.hero_image_feature_id);
        else {
            if (payload.featureData && payload.featureData.build_card_hero_images && payload.featureData.build_card_hero_images.length > 0) {
                mainHeroImage = payload.featureData.build_card_hero_images.find(img => img.is_primary);
                if (mainHeroImage) {
                    formData.append('build_card_hero_image_id', mainHeroImage.build_card_hero_image_id);
                }
            } else if (payload.mainFeature) {
                mainHeroImage = payload.mainFeature.build_card_hero_images.find(img => img.is_primary);
                if (mainHeroImage) {
                    formData.append('build_card_hero_image_id', mainHeroImage.build_card_hero_image_id);
                }
            }
        }
        if(payload.create_group) formData.append('create_group', 'true');
        if(payload.nodeId) formData.append('node_id', payload.nodeId);
        if(payload.image_attributes && payload.image_attributes.height) formData.append('image_attributes[height]', payload.image_attributes.height);
        if(payload.image_attributes && payload.image_attributes.width) formData.append('image_attributes[width]', payload.image_attributes.width);
         let value = (payload.dls_used) ? 'true' : 'false';
         formData.append('dls_used',value);
        return this.http.post(url,formData);
    }

    getArtBoardNameValidation(projectId, story_id, artBoardName, nodeId?,bchId?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.checkArtBoardName}`;
        url = url.replace('{project_id}', projectId).replace('{artboard_name}', artBoardName).replace('{storyId}', story_id);
        
        if (nodeId) url = url + `&node_id=${nodeId}`;
        if(bchId) url = url +`&bchi_id=${bchId}`;
        return this.http.get(url);
    }
    promoteDocument(projectId,builder_feature_id,document_id,payload){
        let url =`${this.baseUrl}${uiwConstants.uiwApiEndPoints.promotingDocument}`
        url = url.replace('{project_id}', projectId);
        url = url.replace('{builder_feature_id}', builder_feature_id);
        url = url.replace('{document_id}', document_id);
        //promotingDocument:'/api/v1/uiw/projects/{project_id}/stories/{builder_feature_id}/documents/{document_id}/promote'
        return this.http.put(url,payload);   
    }
    
    allMentions(projectId){
        let url =`${this.baseUrl}${uiwConstants.uiwApiEndPoints.mentions}`
        url = url.replace('{project_id}', projectId);
        return this.http.get(url);   
    }
    markAsRead(projectId, notificationId?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.markAsRead}`;
        url =  url.replace('{project_id}', projectId);
        let payload = {};
        if (notificationId) {
            payload = {
                notification_id: notificationId,
            };
        }
        return this.http.put(url, payload);
    }
    //MERGINGTRACKER-16
    getStoryForFilter(projectId,platform,role?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getStoryForFilter}`;
        url = url.replace('{projectId}', projectId);
        if(platform){
         url = `${url}&platform_name=${platform}`;
        }
        if(role){
            url = `${url}&role_id=${role}`;
        }
        return this.http.get(url);
    }

    getFeatureNotes(build_card_Id){
        let url = `${this.builder360Url}${uiwConstants.uiwApiEndPoints.featureNotes}`;
        url = url.replace('{build_card_Id}', build_card_Id);
        return this.http.get(url);
    }

    getYoutubeData(videoId) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getYoutubeData}`;
        uri = uri.replace("{video_id}", videoId);
        return this.http.get(uri);
    }
    getWhiteBoardId(build_card_Id){
        let url =`${this.baseUrl}${uiwConstants.uiwApiEndPoints.getWhiteBoard}`;
        url = url.replace('{studio_build_card_id}', build_card_Id);
        return this.http.get(url);
    }
    saveWhiteBoardId(build_card_Id,payload){
        let url =`${this.baseUrl}${uiwConstants.uiwApiEndPoints.saveWhiteBoard}`;
        url = url.replace('{studio_build_card_id}', build_card_Id);
        return this.http.post(url,payload);
    }

    getSketchForStory(project_Id){
        let url =`${this.baseUrl}${uiwConstants.uiwApiEndPoints.getSketchForStory}`;
        url = url.replace('{project_Id}', project_Id);
        return this.http.get(url);
    }
    setSketchForStory(project_Id,payload){
        let url =`${this.baseUrl}${uiwConstants.uiwApiEndPoints.setSketchForStory}`;
        url = url.replace('{project_Id}', project_Id);
        return this.http.put(url,payload);
    }        
    getPlatforms(studio_build_card_id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getPlatforms}`;
        url = url.replace('{studio_build_card_id}', studio_build_card_id);
        return this.http.get<any[]>(`${url}`);
    }
    savePlatform(payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.savePlatform}`;
        return this.http.put(url, payload);
    }
    updatePlatform(sketch_detail_id,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.updatePlatform}`;
        url = url.replace('{sketch_detail_id}',sketch_detail_id);
        return this.http.put(url, payload);
    }
    deleteAttach(attachmentId){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteAttachment}`;
        url = url.replace('{attachment_id}',attachmentId);
        return this.http.delete(url);
    }

    maintenanceApi(){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.maintenance}`;
        return this.http.get(url);
    }

    readReleaseNotes(){
        const empObj={};
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.readNotes}`;
        url += url.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.put(url,empObj);
    }
    readMaintenance(){
        const empObj={};
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.readMaintenance}`;
        url += url.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.put(url,empObj);
    }
    deleteScreen(buildCardFeatureId, studioBuildCardId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deleteScreen}`;
        url = url.replace('{build_card_feature_id}', buildCardFeatureId);
        url = url.replace('{studio_build_card_id}', studioBuildCardId);
        return this.http.delete(url);
    }
    getVideoTutorials() {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.videoTutorials}`;
        return this.http.get(uri);
    }
    getVideoErrorTypes() {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.videoErrorTypes}`;
        return this.http.get(uri);
    }
    //MERGINGTRACKER-17
    fetchPlatform(payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.hotSpotPlatform}`;
        url = url.replace('{id}', payload.id);
        url = url.replace('{build_card_id}',payload.build_card_id);
        url = url.replace('{build_card_hero_image_id}', payload.build_card_hero_image_id);
         return this.http.get(url);
    }
    linkSubStory(parent_feature_id,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.subStoryLinking}`;
        url = url.replace('{parent_feature_id}', parent_feature_id);
        return this.http.put(url,payload);
    }

    unlinkApi(parent_feature_id,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.unlinkSub}`;
        url = url.replace('{parent_feature_id}', parent_feature_id);
        return this.http.put(url,payload);
    }
    
    getFolderFiles(buildCardId) {
        let url = `${this.builder360Url}${uiwConstants.uiwApiEndPoints.internalDocuments}`;
        url = url.replace('{build_card_id}', buildCardId);
        return this.http.get(url);
    }

    getRecentChanges({ projectId, platform }, storyId?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getRecentChanges}`;
        url = url.replace('{project_id}', projectId);
        url = url.replace('{platform}', platform);
        url = storyId ? url + '&story_id='+storyId  : url;
        return this.http.get(url);
    }

    markReadRecentChanges({ projectId, storyId }){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.readRecentChanges}`;
        url = url.replace('{project_id}', projectId);
        url = url.replace('{story_id}', storyId);
        url += url.includes('?') ? '&notoast=1' : '?notoast=1';
        return this.http.put(url, {});
    }

    deliverDesignToCustomers(projectId, payload,role) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.deliverDesignToCustomers}`;
        url = url.replace('{project_id}', projectId);
        if(role){
            url = `${url}?role_id=${role}`;
        }
        return this.http.put(url, payload);
    }

    getBaseSketches(projectId, platform,role) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getBaseSketches}`;
        url = url.replace('{project_id}', projectId);
        url = url.replace('{platform}', platform);
        if(role){
            url = `${url}&role_id=${role}`;
        }
        return this.http.get(url); 
    }
    copyRoles(payload,build_card_id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.copyRole}`;
        url = url.replace('{build_card_id}',build_card_id);
        return this.http.post(url, payload);
    }

    getBDSElements(stduio_feature_id) {
        let url = `${this.baseSketch}${uiwConstants.uiwApiEndPoints.getBlocks}`;
        url = url.replace('{stduio_feature_id}', stduio_feature_id);
        return this.http.get(url);
    }

    platformSwappApi(buildCardFeatureId, payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.platformSwapping}`;
        url = url.replace('{build_card_feature_id}', buildCardFeatureId);
        return this.http.put(url, payload);
    }

    toggleNavHotspot(studioBuildCardId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.toggleNavHotspot}`;
        url = url.replace('{studio_build_card_id}', studioBuildCardId);
        return this.http.put(url, {});
    }

    requestToPromoteSketch(featureInfo) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.requestToPromoteSketch}`;
        return this.http.post(url, featureInfo);
    }

    compareFeatureSketch(featureSketchId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.compareFeatureSketch}`;
        url = url.replace('{feature_sketch_id}', featureSketchId);
        return this.http.get(url);
    }

    approveSketch(featureSketchId: number) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.approveSketch}`;
        url = url.replace('{requested_sketch_id}', `${featureSketchId}`);
        return this.http.put(url, {});
    }

    rejectSketch(featureSketchId: number) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.rejectSketch}`;
        url = url.replace('{requested_sketch_id}', `${featureSketchId}`);
        return this.http.put(url, {});
    }
    
    triggerCodeGeneration(featureId, payload) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.triggerCodeGeneration}`;
        url = url.replace('{build_card_feature_id}', featureId) + '?notoast=1';
        return this.http.put(url, payload);
    }

    hotSpotRefactor(buildCardFeatureId,build_card_hero_image_id,build_card_id) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.hotSpotRefactorApi}`;
        url = url.replace('{build_Card_feature_id}', buildCardFeatureId);
        url = url.replace('{build_card_hero_image_id}', build_card_hero_image_id);
        url = url.replace('{build_card_id}', build_card_id);
        return this.http.get(url);
    }

    storySearchApiCall(project_Id,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.story_Search_Filter}`;
        url = url.replace('{project_id}', project_Id);
        return this.http.post(url,payload);
    }

    cmtDataFeedBack(user_story_id){
        const url = `${this.cmtFeebBackUrl}${uiwConstants.uiwApiEndPoints.feedBackLoop}`.replace('{user_story_id}', user_story_id);
        return this.http.get(url);
    }

    getDlsFDL(key,id){
        return this.http.get(`${this.nowBaseUrl}${uiwConstants.uiwApiEndPoints.fetch_feature_dlsFDL}`
        .replace('{cmt_feature_id}', key)
        .replace('{id}', id));
    }
    reqToPromoteApiCall(storyIdObj){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.reqToPromote}`;
        return this.http.post(url,storyIdObj);
    }

    socketAuthGet(){
        let url = `${this.trackerSocekt}`;
        return this.http.get(url);
    }

    socketAuthPost(payload){
        let url = `${this.trackerSocekt}`;
        return this.http.post(url, payload)
    }

    getDlsUsageApiCall(project_Id, story_id?: string) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getDlsUsage}`;
        url = url.replace('{project_id}', project_Id);
        let params = new HttpParams();
        if (story_id) {
           params = params.set('story_id', story_id);
        }
        return this.http.get(url, { params });
    }

    getDestinationApiCall(buildCardId, platform, device, role, studioPlatformIds?, trackerId?, per_page?, page?, searchQuery? ,heroImgId?) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getDestinationData}`;
        url = url.replace("{buildCardId}", buildCardId).replace("{platform}", platform).replace("{device}", device);
        if(role){
            url = `${url}&role_id=${role}`;
        }
        if(studioPlatformIds) url = `${url}&studio_platform_ids=${studioPlatformIds}`;
        if(trackerId) url = `${url}&tracker_project_id=${trackerId}`;
        if(searchQuery) url = `${url}&search_query=${encodeURIComponent(searchQuery)}`;
        if(page) url = `${url}&page=${page}`;
        if(per_page) url = `${url}&per_page=${per_page}`;
        if(heroImgId) url = `${url}&hero_image_id=${heroImgId}`;
        return this.http.get(url);
    }

    getRelatedStories(projectId,storyId,nodeId){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.relatedStories}`;
        url = url.replace('{project_id}', projectId);
        url = url.replace('{story_id}', storyId);
        url = url.replace('{node_id}', nodeId);
        return this.http.get(url);
    }

    setAsHeroApi(bchId,payload,device,platform){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.setHero}`;
        url = url.replace('{bchId}', bchId);
        url = url.replace('{device}', device);
        url = url.replace('{platform}', platform);
        return this.http.put(url,payload);
        
    }

    getFigmaMismatchApiCall(project_Id, story_id) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getFigmaVersionMismatch}`;
        url = url.replace('{projectId}', project_Id);
        url = url.replace('{storyId}', story_id);
        url =`${url}?can_deliver=${true}`;
        return this.http.get(url);
    }

    getBchatDataApi(builder360Id,userEmail){
        let url = `${this.builder360Url}${uiwConstants.uiwApiEndPoints.bChat}`;
        url = url.replace('{builder360Id}', builder360Id);
        url = url.replace('{userEmail}', userEmail);
        return this.http.get(url);
    }

    journeyApi(project_id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.journey}`;
        url = url.replace('{project_id}', project_id);
        return this.http.get(url);
    }
    
    getBrandingApi(buildCardId){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getBranding}`;
        url = url.replace('{buildCardId}',buildCardId);
        return this.http.get(url);
    }

    getFigmaAcessApi(project_id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.figmaAccess}`;
        url = url.replace('{project_id}', project_id);
        return this.http.get(url);
    }

    getFinishedStories(project_Id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.finishedStories}`;
        url = url.replace('{project_id}', project_Id);
        return this.http.get(url)
        
    }

    resolvedByUser(projectId, commentId) {
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.resolvedByUser}`;
        url = url.replace('{project_id}', `${projectId}`);
        url = url.replace('{commentId}', `${commentId}`);
        return this.http.put(url, {});
    }

    projectMetric(project_Id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getProjectMetric}`;
        url = url.replace('{project_Id}', project_Id);
        return this.http.get(url);
    }

    allJourneys(project_Id,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getAllJourneys}`;
        url = url.replace('{project_Id}', project_Id);
        return this.http.post(url,payload);
    }

    singleJourney(project_Id,journey_Id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getSingleJourney}`;
        url = url.replace('{project_id}', project_Id);
        url = url.replace('{journey_id}', journey_Id);
        return this.http.get(url);
    }

    getAllStoriesWithFilterJourneyApi(project_Id,journey_Id ,payload){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.getAllStoriesWithFilter}`;
        url = url.replace('{project_id}', project_Id);
        url = url.replace('{journey_id}', journey_Id);
        return this.http.post(url,payload);

    }

    setDueDate(feature, projectId) {
        let uri = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.storyStatus}`;
        uri = uri.replace('{storyId}', feature.story.id).replace('{projectId}', projectId);
        let payload = {};
        payload['version'] = feature?.build_card_hero_images[0].version;
        payload['studio_feature_id'] = feature.studio_feature_id;
        payload['story'] = { dev_release_at: feature.story.dev_release_at };
        return this.http.put(uri, payload);
    }

    getReferenceScreens(studio_feature_id,platform,role_id,story_id){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.referenceScreens}`;
        url = url.replace('{studio_feature_id}', studio_feature_id);
        url = url.replace('{platform}', platform);
        url = url.replace('{role_id}', role_id);
        url = url.replace('{story_id}', story_id);
        return this.http.get(url);
    }

    getAllSketchUrl(projectId){
        let url = `${this.baseUrl}${uiwConstants.uiwApiEndPoints.sketchUrl}`;
        url = url.replace('{project_id}', projectId);
        return this.http.get(url);
    }
 }
